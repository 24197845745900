import TopNav from './topnav';
import Addmove from './addmove';
import Search from './search';
import Saved from './saved';
import Feed from './feed';
import './../App.css';
import BottomNav from './bottomnav';

export default function Main(props) {
    const user = props.user;
    const leaveSite = props.leaveSite;
    const page = props.page;
    const setPage = props.setPage;

    if (page === 0) {
        return (
            <div className="App">
                <TopNav user={user} leaveSite={leaveSite}/>
                <Feed />
                <BottomNav page={page} setPage={setPage}/>
            </div>
        )
    } else if (page === 1) {
        return (
            <div className="App">
                <TopNav user={user} leaveSite={leaveSite}/>
                <Addmove />
                <BottomNav page={page} setPage={setPage}/>
            </div>
        )
    }  else if (page === 2) {
        return (
            <div className="App">
                <TopNav user={user} leaveSite={leaveSite}/>
                <Search />
                <BottomNav page={page} setPage={setPage}/>
            </div>
        )
    }  else if (page === 3) {
        return (
            <div className="App">
                <TopNav user={user} leaveSite={leaveSite}/>
                <Saved />
                <BottomNav page={page} setPage={setPage}/>
            </div>
        )
    }
    
}