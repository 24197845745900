import './../App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import { pink } from '@mui/material/colors';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddIcon from '@mui/icons-material/Add';

export default function BottomNav(props) {
    const [value, setValue] = React.useState(0);
    const page = props.page;
    const setPage = props.setPage;

    return (
        <div className="bottomnav">
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                setValue(newValue);
                }}
            >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick={() => setPage(0)}/>
                <BottomNavigationAction label="Search" icon={<SearchIcon />} onClick={() => setPage(2)}/>
                <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} onClick={() => setPage(3)}/>
                <BottomNavigationAction label="Add" icon={<AddIcon />} onClick={() => setPage(1)}/>
            </BottomNavigation>
        </div>
    )
}