import './../App.css';
import logo from './../logo2.png';
import LogoutIcon from '@mui/icons-material/Logout';


export default function Topnav(props) {
    const user = props.user;
    const leaveSite = props.leaveSite;

    // console.log('user: ', props.user);
    return (
        <div className="topnav">
            <ul>
                <li><a href="#"><img src={logo} className="App-logo2" alt="logo" /></a></li>
                <li className="lastchild"><LogoutIcon className='topnavicon' onClick={() => leaveSite()} /></li>
                <li className='lastchild'><img className="profilepic" src={user.photoURL} alt='display name' referrerPolicy='no-referrer'/></li>
            </ul>
        </div>
    )
}