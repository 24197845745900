import logo from './logo2.png';
import './App.css';
import Main from './components/main';
import { useState, setState } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getDatabase, ref, set, child, push, update, onValue } from "firebase/database";
import Button from '@mui/material/Button';

const firebaseConfig = {

  apiKey: "AIzaSyDCKUUxGxyoWYpPPiCCSDDmMpnNPtDlgho",

  authDomain: "dojigo-3b6e1.firebaseapp.com",

  projectId: "dojigo-3b6e1",

  storageBucket: "dojigo-3b6e1.appspot.com",

  messagingSenderId: "547630945221",

  appId: "1:547630945221:web:f443c9e2c4af0668b9f1ac",

  measurementId: "G-1R0HSZ57WN",

  databaseURL: "https://dojigo-3b6e1-default-rtdb.firebaseio.com/"

};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();

const auth = getAuth();

const database = getDatabase(app);

function App() {
  const [ user, setUser ] = useState(null);
  const [ page, setPage ] = useState(0);

  function signIn() {
    signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // console.log("Credential: ", credential);
      const token = credential.accessToken;
      // console.log("Token: ", token);
      // The signed-in user info.
      const user = result.user;
      console.log(user);
      setUser(result.user);
      writeUserData(user.uid, user.displayName, user.email, user.photoURL)
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  }
  function leaveSite() {
    signOut(auth).then(() => {
      // Sign-out successful.
      setUser(null)
    }).catch((error) => {
      // An error happened.
    });
  }

  function writeUserData(userId, name, email, imageUrl) {
    console.log("Write User Data!!!")
    const db = getDatabase();
    set(ref(db, 'users/' + userId), {
      username: name,
      email: email,
      profile_picture : imageUrl
    });
  }

  if (user === null) {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo1" alt="logo" />
          <p>
            <Button variant="contained" onClick={() => signIn()}><b>Log in with Google</b></Button>
          </p>
        </header>
      </div>
    );
  } else {
    return (
      <div className='main'>
          <Main user={user} leaveSite={leaveSite} page={page} setPage={setPage}/>
      </div>
    );
  }
}

export default App;